import { Card } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { signIn } from '../../../store/slices/userSlice';
import { AuthDto } from '@bakeryerp/common';
import { api } from '../../../lib/api';
import { LoginForm } from './LoginForm';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../lib/routes';
import { syncEntries } from '../../../store/slices/entriesSlice';
import { syncTimer } from '../../../store/slices/timerSlice';

interface LoginCardProps {
  className?: string;
}

const LoginCardInner = (props: LoginCardProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onFinish = (values: AuthDto) => {
    api({
      method: 'post',
      url: '/auth/login',
      data: values,
    }).then(({ data }) => {
      dispatch(signIn(data));
      dispatch(syncTimer());
      dispatch(syncEntries());
      history.push('/');
    });
  };

  const onFinishFailed = (errorInfo: any) => {};

  return (
    <Card
      bodyStyle={{
        padding: 30,
      }}
      className={props.className}
    >
      <LoginForm onFinish={onFinish} onFinishFailed={onFinishFailed} />
    </Card>
  );
};

export const LoginCard = styled(LoginCardInner)`
  width: 500px;
`;

import React, { useEffect } from 'react';
import { LoggedInLayout } from '../../components/shared/layout/LoggedInLayout';
import { AttendanceTimer } from '../../components/attendance/AttendanceTimer/AttendanceTimer';
import { LayoutWithMargin } from '../../components/shared/LayoutWithMargin/LayoutWithMargin';
import { CaretRightOutlined, RestOutlined } from '@ant-design/icons';
import { PageHeader } from 'antd';
import { useTranslation } from 'react-i18next';
import { AttendanceLastEntries } from '../../components/attendance/AttendanceLastEntries/AttendanceLastEntries';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/slices/userSlice';
import { useHistory } from 'react-router-dom';

const AttendancePage = () => {
  const { t } = useTranslation('attendance');
  const user = useSelector(userSelector);
  const history = useHistory();

  // useEffect(() => {
  //   if (user && !user?.modules.includes('attendance')) {
  //     history.push('/dashboard');
  //   }
  // }, [user]);

  return (
    <LoggedInLayout>
      <>
        <PageHeader
          title={
            <>
              <CaretRightOutlined /> {t('attendance:timer.title')}{' '}
            </>
          }
          ghost
        />
        <LayoutWithMargin>
          <AttendanceTimer />
          <AttendanceLastEntries />
        </LayoutWithMargin>
      </>
    </LoggedInLayout>
  );
};

export default AttendancePage;

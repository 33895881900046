import { StrikethroughOutlined } from '@ant-design/icons';
import { Badge, Card, List } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { entriesSelector, Entry } from '../../../store/slices/entriesSlice';
import { formattedRange, diffInHHmm } from './utils/entry-formatters';

export interface AttendanceLastEntriesProps {
  className?: string;
}

const EntryDate = styled.div``;
const EntryTimes = styled.div``;
const EntryDuration = styled.div``;
const EntryBadgeDate = styled.div`
  display: flex;
`

export interface EntryListEntry {
  date: string;
  range: string;
  duration: string;
  synced: boolean;
}

const AttendanceLastEntriesInner = (props: AttendanceLastEntriesProps) => {
  const entries: Entry[] = useSelector(entriesSelector);
  const mappedEntries: EntryListEntry[] = useMemo(
    () =>
      entries.slice(0, 8).map((entry) => ({
        date: moment(entry.start).format('DD.MM'),
        range: formattedRange(entry.start, entry.end),
        duration: diffInHHmm(entry.start, entry.end),
        synced: entry.synced,
      })),
    [entries],
  );
  return (
    <Card className={props.className}>
      <List
        itemLayout="horizontal"
        dataSource={mappedEntries}
        renderItem={(item) => (
          <List.Item>
            <EntryBadgeDate>
              <Badge color={item.synced ? 'green' : 'gold'} />
              <EntryDate>{item.date}</EntryDate>
            </EntryBadgeDate>
            <EntryTimes>{item.range}</EntryTimes>
            <EntryDuration>{item.duration}</EntryDuration>
          </List.Item>
        )}
      />
    </Card>
  );
};

export const AttendanceLastEntries = styled(AttendanceLastEntriesInner)`
  margin-top: 24px;
`;

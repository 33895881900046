import { FormInstance } from 'antd/lib/form';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { DatePicker, Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useCompanyTimeOffReasons } from '../hooks/useCompanyTimeOffReasons';

export interface CreaateTimeOffFormProps {
  className?: string;
  form: FormInstance;
  formSubmitted: (values: any) => void;
}

const { RangePicker } = DatePicker;

const CreateTimeOffFormInner = (props: CreaateTimeOffFormProps) => {
  const { form, formSubmitted } = props;
  const { t } = useTranslation(['attendance', 'common']);

  const timeOffReasons = t('timeOffs.create.form.reason.values', {
    returnObjects: true,
  });

  const { data: tOffReasons = [] } = useCompanyTimeOffReasons();

  return (
    <div className={props.className}>
      <Form
        name="basic"
        form={form}
        initialValues={{}}
        onFinish={formSubmitted}
        hideRequiredMark
      >
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.worked !== currentValues.worked ||
            prevValues.hours !== currentValues.hours
          }
        >
          {(input) => (
            <Form.Item
              label={t('timeOffs.create.form.start')}
              name="start"
              rules={[
                {
                  required: true,
                  message: t('timeOffs.create.form.errors.startRequired'),
                },
              ]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={t('common:formats.date')}
                placeholder={t('timeOffs.create.form.start')}
                disabledDate={(date) => {
                  return (
                    moment(date).isBefore(moment().startOf('day')) ||
                    (input.getFieldValue('end') &&
                      moment(date).isSameOrAfter(input.getFieldValue('end')))
                  );
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.worked !== currentValues.worked ||
            prevValues.hours !== currentValues.hours
          }
        >
          {(input) => (
            <Form.Item
              label={t('timeOffs.create.form.end')}
              name="end"
              rules={[
                {
                  required: true,
                  message: t('timeOffs.create.form.errors.endRequired'),
                },
              ]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={t('common:formats.date')}
                placeholder={t('timeOffs.create.form.end')}
                disabledDate={(date) => {
                  return (
                    input.getFieldValue('start') &&
                    moment(date).isSameOrBefore(input.getFieldValue('start'))
                  );
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item
          label={t('timeOffs.create.form.reason.label')}
          name="reason"
          rules={[
            {
              required: true,
              message: t('timeOffs.create.form.errors.reason'),
            },
          ]}
        >
          <Select>
            {tOffReasons.map(reason => (
              <Select.Option value={reason.id} key={reason.value}>
                {reason.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
};

export const CreateTimeOffForm = styled(CreateTimeOffFormInner)``;

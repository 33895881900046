import React from 'react';
import styled from 'styled-components';
import { LoginCard } from './components/LoginCard';

export interface LoginProps {
  className?: string;
}

const LoginInner = (props: LoginProps) => {
  return (
    <div className={props.className}>
      <LoginCard />
    </div>
  );
};

export const Login = styled(LoginInner)`
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

import { entriesReducer } from './slices/entriesSlice';
import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from './slices/userSlice';
import { timerReducer } from './slices/timerSlice';

const persistedState = localStorage.getItem('reduxState')
  ? JSON.parse(localStorage.getItem('reduxState'))
  : {}

export const reduxStore = configureStore({
  reducer: {
    auth: authReducer,
    timer: timerReducer,
    entries: entriesReducer
  },
  preloadedState: persistedState,
  devTools: true,
});

reduxStore.subscribe(() => {
  localStorage.setItem('reduxState', JSON.stringify(reduxStore.getState()))
})


export type RootState = ReturnType<typeof reduxStore.getState>
export default reduxStore;
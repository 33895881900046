import { useSelector } from 'react-redux';
import { startedSelector } from '../../../../../store/slices/timerSlice';
import { useEffect, useState } from 'react';
import useInterval from '@use-it/interval';
import { getElapsedTimeFromFormatted as getElapsedTimeFormatted } from '../../../attendance.utils';

const INITIAL_FORMATTED_TIME_VALUE = '00:00:00';

export const useFormattedTimer = () => {
  const started = useSelector(startedSelector);
  const [runningFor, setRunnigFor] = useState(INITIAL_FORMATTED_TIME_VALUE);

  useEffect(() => {
    if (started === null) {
      setRunnigFor(INITIAL_FORMATTED_TIME_VALUE);
    } else {
      setRunnigFor(getElapsedTimeFormatted(started))
    }
  }, [started]);

  useInterval(() => {
    if (started == null) {
      return;
    }
    setRunnigFor(getElapsedTimeFormatted(started));
  }, 1000);

  return { runningFor, started };
};

import { CreateTimeOffDto } from '@bakeryerp/common';
import { Button, Form, Modal } from 'antd';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { queryCache, useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { api } from '../../../lib/api';
import { userSelector } from '../../../store/slices/userSlice';
import { CreateTimeOffForm } from './components/CreaateTimeOffForm';

export interface CreateTimeOffProps {
  className?: string;
}

const createTimeOff = async (values: CreateTimeOffDto) => {
  return api({
    method: 'POST',
    url: `/attendance/time-off`,
    data: values,
  });
};

const CreateTimeOffInner = (props: CreateTimeOffProps) => {
  const { t } = useTranslation('attendance');
  const [visible, setVisible] = useState(false);
  const [mutate, { isLoading }] = useMutation(createTimeOff, {
    onSuccess: () => {
      console.log('up');
      queryCache.invalidateQueries('getUserTimeOffs')
    },
  });
  const [form] = Form.useForm();
  const user = useSelector(userSelector);

  const handleFormSubmitted = useCallback((values) => {
    mutate({
      ...values,
      user: user.id,
    }).then(() => {
      setVisible(false);
      form.resetFields();
    });
  }, []);

  const handleOk = useCallback(() => {
    form.submit();
  }, []);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <div className={props.className}>
      <Button onClick={() => setVisible(true)} type="primary">
        {t('attendance:timeOffs.add')}
      </Button>
      <Modal
        title={t('timeOffs.create.title')}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={isLoading}
      >
        <CreateTimeOffForm form={form} formSubmitted={handleFormSubmitted} />
      </Modal>
    </div>
  );
};

export const CreateTimeOff = styled(CreateTimeOffInner)``;

import { useQuery } from 'react-query';
import { api } from '../../../../lib/api';

export const useCompanyTimeOffReasons = (onSuccess?: (values) => void) => {
  const fetchTimeOffReasons = () =>
    api({
      url: `/attendance/time-off-reasons`,
      method: 'GET',
    }).then(({ data }) => data);
  const { isLoading, data } = useQuery(
    ['getCompanyTimeOffReasons'],
    fetchTimeOffReasons,
    {
      onSuccess,
    },
  );

  return {
    isLoading,
    data,
  };
};

import { Form, Typography } from 'antd';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import React from 'react';
import styled from 'styled-components';
import { AuthDto } from '@bakeryerp/common';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const LoginActions = styled.div``;

interface LoginFormProps {
  onFinish: (values: AuthDto) => void;
  onFinishFailed: (errorinfo: any) => void;
  className?: string;
}

const LoginFormInner = (props: LoginFormProps) => {
  const { t } = useTranslation(['login']);
  
  return (
    <Form
      layout="vertical"
      name="basic"
      initialValues={{ email: null, password: null }}
      className={props.className}
      onFinish={props.onFinish}
      onFinishFailed={props.onFinishFailed}
    >
      <h1>Bakery</h1>
      <Form.Item
        name="email"
        rules={[{ required: true, message: 'Please input your email!' }]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder={t('email')}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder={t('password')}
        />
      </Form.Item>

      <LoginActions>
        <Button type="default" htmlType="submit">
          {t('login')}
        </Button>
      </LoginActions>
    </Form>
  );
};

export const LoginForm = styled(LoginFormInner)`
  .site-form-item-icon {
    opacity: 0.5;
  }

  h1 {
    text-align: center;
  }
`;

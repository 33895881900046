import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useFormattedTimer } from '../../components/TimerRunningForDisplay/hooks/useFormattedTimer';

export interface TimerDataContentRowProps {
  className?: string;
}

const DataLabel = styled.div`
  color: #bbbbbb;
  font-size: 14px;
`;

const DataValue = styled.div`
  font-size: 22px;
  color: #000000;
`;

const TimerDataContentRowInner = (props: TimerDataContentRowProps) => {
  const { t } = useTranslation(['attendance']);
  const { runningFor, started } = useFormattedTimer();

  const startedDisplay = useMemo(() => {
    return started ? moment(started).format('HH:mm') : '00:00';
  }, [started]);

  return (
    <div className={props.className}>
      <div>
        <DataLabel>{t('attendance:timer.data.started')}</DataLabel>
        <DataValue>{startedDisplay}</DataValue>
      </div>
      <div>
        <DataLabel style={{ textAlign: 'right' }}>
          {t('attendance:timer.data.time')}
        </DataLabel>
        <DataValue>{runningFor}</DataValue>
      </div>
    </div>
  );
};

export const TimerDataContentRow = styled(TimerDataContentRowInner)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { api } from '../../lib/api';
import { getDeviceInfo } from '../../lib/getDeviceInfo';
import { RootState } from '../store';
import { entriesSlice, Entry } from './entriesSlice';

export const startTimer = createAsyncThunk(
  'timer/start',
  async (payload, { dispatch, rejectWithValue }) => {
    console.log(`start timer`);

    const entry: Entry = {
      id: uuid(),
      start: moment().format(),
      end: null,
      synced: true,
    };

    dispatch(timerSlice.actions.setEntry(entry));
    dispatch(entriesSlice.actions.addEntry(entry));

    let location;

    try {
      location = await getDeviceInfo();
    } catch (error) {
      console.log(error);
    }

    await api({
      method: 'POST',
      url: '/attendance/timer/start',
      data: {
        startLocation: location,
        ...entry,
      },
    }).catch((e) => {
      console.log(e);
    });

    // try {

    // } catch (e) {
    //   console.log(e);
    //   dispatch(
    //     entriesSlice.actions.updateEntry({
    //       ...entry,
    //       synced: false,
    //     }),
    //   );
    //   throw e;
    // }
  },
);

export const stopTimer = createAsyncThunk(
  'timer/stop',
  async (payload, { dispatch, getState }) => {
    const end = moment().format();
    const { timer } = <RootState>getState();

    dispatch(
      entriesSlice.actions.updateEntry({
        ...timer.entry,
        end,
      }),
    );

    dispatch(timerSlice.actions.setEntry(null));

    let deviceInfo;

    try {
      deviceInfo = await getDeviceInfo();
    } catch (e) {}

    await api({
      method: 'PUT',
      url: `attendance/entry/${timer.entry.id}/stop`,
      data: {
        end,
        endLocation: deviceInfo,
      },
    });
  },
);

export const syncTimer = createAsyncThunk(
  'timer/sync',
  async (payload, { dispatch }) => {
    const { data } = await api({
      method: 'GET',
      url: '/attendance/timer/running',
    });
    if (data) {
      dispatch(timerSlice.actions.setEntry(data));
      dispatch(entriesSlice.actions.updateEntry(data));
    } else {
      dispatch(timerSlice.actions.setEntry(null));
    }
  },
);

export interface TimerStore {
  started: null | number;
  entry: Entry | null;
}

const timerSlice = createSlice({
  name: 'timer',
  initialState: {
    entry: null,
  } as TimerStore,
  reducers: {
    setEntry: (state, action: PayloadAction<null | Entry>) => {
      state.entry = action.payload;
    },
    clearTimer: (state) => {
      state.entry = null;
      state.started = null;
    },
  },
  extraReducers: {
    [stopTimer.rejected.type]: (state) => {},
    [startTimer.rejected.type]: (state, action) => {},
  },
});

export const { clearTimer } = timerSlice.actions;

export const runningEntrySelector = (state) => state.timer.entry;
export const startedSelector = (state) =>
  new Date(state.timer.entry?.start).getTime() || null;
export const timerReducer = timerSlice.reducer;

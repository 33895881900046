import moment from "moment";

export const diffInHHmm = (start, end) => {
  if (!end) {
    return '-';
  }
  const duration = moment.duration(moment(end).diff(start));
  return `${duration.hours()}h ${duration.minutes()}m`;
};

export const formattedRange = (start, end): string => {
  const formattedStart = moment(start).format('HH:mm');
  if (!end) {
    return formattedStart;
  }
  const formattedEnd = moment(end).format('HH:mm');
  return `${formattedStart} - ${formattedEnd}`;
};
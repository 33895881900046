import AttendancePage from '../pages/attendance/attendance';
import TimeOffsPage from '../pages/attendance/time-offs';
import DashboardPage from '../pages/dashboard';
import LoginPage from '../pages/login';
import ProtectedPage from '../pages/protected';

export interface Route {
  path: string;
  component: any;
  private?: boolean;
}

export const routes = {
  login: {
    path: '/login',
    component: LoginPage,
  } as Route,
  dashboard: {
    path: '/dashboard',
    component: DashboardPage,
  } as Route,
  timer: {
    path: '/attendance/timer',
    component: AttendancePage,
  } as Route,
  timeOffs: {
    path: '/attendance/time-offs',
    component: TimeOffsPage
  } as Route,
  protected: {
    path: '/protected',
    component: ProtectedPage,
    protected: true,
  } as Route,
};

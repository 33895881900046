import { CaretRightOutlined } from '@ant-design/icons';
import { PageHeader } from 'antd';
import React from 'react';
import { AttendanceLastEntries } from '../components/attendance/AttendanceLastEntries/AttendanceLastEntries';
import { AttendanceTimer } from '../components/attendance/AttendanceTimer/AttendanceTimer';
import { LoggedInLayout } from '../components/shared/layout/LoggedInLayout';
import { LayoutWithMargin } from '../components/shared/LayoutWithMargin/LayoutWithMargin';

const DashboardPage: React.FC = () => (
  <LoggedInLayout>
    <>
      <PageHeader title={<>Dashboard</>} ghost />
      <LayoutWithMargin></LayoutWithMargin>
    </>
  </LoggedInLayout>
);

export default DashboardPage;

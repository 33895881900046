import { Moment } from 'moment';
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../lib/api';
import { RootState } from '../store';

export interface Entry {
  id: string;
  start: Date | string ;
  end?: Date | string;
  synced: boolean;
}

export interface EntryStore {
  entries: Entry[];
}

export const syncEntries = createAsyncThunk(
  'entries/sync',
  async (payload, { dispatch, getState }) => {
    const { auth: { user } } = <RootState>getState()
    const { data: { items } } = await api({
      method: 'GET',
      url: `/attendance/entries/${user.id}`,
    });
    for (const item of items) {
      dispatch(entriesSlice.actions.updateEntry({
        ...item,
        synced: true
      }))
    }
  },
);


export const entriesSlice = createSlice({
  name: 'entries',
  initialState: {
    entries: [],
  } as EntryStore,
  reducers: {
    clearEntries: (state) => {
      state.entries = []
    },
    addEntry: (state, action: PayloadAction<Entry>) => {
      state.entries = [
        action.payload,
        ...state.entries,
      ]
    },
    updateEntry: (state, action: PayloadAction<Entry>) => {
      if (!state.entries.find(entry => entry.id === action.payload.id)) {
        state.entries = [...state.entries, action.payload]
      }
      state.entries = state.entries.map(entry => {
        if (action.payload.id === entry.id) {
          return {
            ...entry,
            ...action.payload,
          }
        }
        return entry;
      });
    }
  },
  extraReducers: {

  },
});

export const { clearEntries } = entriesSlice.actions

export const entriesSelector = (state) => state.entries.entries;
export const entriesReducer = entriesSlice.reducer;

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import store from '../../../store/store';
import { Layout } from 'antd';
import { AppHeader } from '../AppHeader/AppHeader';

export interface LoggedInLayoutProps {
  className?: string;
  children: JSX.Element[] | JSX.Element;
}

const StyledContent = styled(Layout.Content)`
  margin: 0 4rem;
`;

export const theme = {
  color: {
    primary: '#0070f3',
  },
};

const StyledLayoutContent = styled(Layout.Content)`
  min-height: calc(100vh - 64px);
`;

const LoggedInLayoutInner = (props: LoggedInLayoutProps) => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Layout>
          <AppHeader />
          <StyledLayoutContent>{props.children}</StyledLayoutContent>
        </Layout>
      </Provider>
    </ThemeProvider>
  );
};

export const LoggedInLayout = LoggedInLayoutInner;

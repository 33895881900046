import axios from 'axios';
import { JWT_LOCAL_STORAGE_KEY } from './consts';

export const DOCKER_API_SERVER_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : `https://api.maetrik.io/api`;

const api = axios.create({
  baseURL: DOCKER_API_SERVER_URL,
});

api.interceptors.request.use(function (config) {
  const token = localStorage.getItem(JWT_LOCAL_STORAGE_KEY);
  config.headers.Authorization = token;

  return config;
});

// // Add a response interceptor
// api.interceptors.response.use(function (response) {
// 	return response;
// }, function (error) {
// 	message.error(error.message);
// 	if (error.status === 401) {
// 		reduxStore.dispatch(signUserOut())
// 	}
// 	return Promise.reject(error);
// });

export const setAuthToken = (token) => {
  api.defaults.headers.common['Authorization'] = token;
};

export { api };

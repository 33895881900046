import { UserOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { userSelector } from '../../../store/slices/userSlice';
import { LogoutButton } from './components/LogoutButton';

export interface HeaderNameDropdownProps {
  className?: string;
}

const menu = (
  <Menu>
    <Menu.Item key="0">
      <LogoutButton />
    </Menu.Item>
  </Menu>
);

const HeaderNameDropdownInner = (props: HeaderNameDropdownProps) => {
  const user = useSelector(userSelector);
  return (
    <Dropdown className={props.className} overlay={menu} trigger={['click']}>
      <DropdownLink>
        <UserLink onClick={(e) => e.preventDefault()}>
          <UserOutlined
            style={{
              marginRight: 10,
            }}
          />
          {user?.name} {user?.surname}
        </UserLink>
      </DropdownLink>
    </Dropdown>
  );
};

const UserLink = styled.a`
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

const DropdownLink = styled.div`
  ${UserLink} {
    margin-left: 10px;
  }
`;

export const HeaderNameDropdown = styled(HeaderNameDropdownInner)``;

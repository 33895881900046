import { LogoutOutlined } from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { signUserOut } from "../../../../store/slices/userSlice";

export interface LogoutButtonProps {
  className?: string;
}

const LogoutButtonInner = (props: LogoutButtonProps) => {
  const dispatch = useDispatch();
  const onLogoutClick = () => dispatch(signUserOut());

  const { t } = useTranslation(['translation']);

  return (
    <div onClick={onLogoutClick} className={props.className}>
      <span>{t('logout')}</span>
      <LogoutOutlined />
    </div>
  );
};

export const LogoutButton = styled(LogoutButtonInner)`
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;

  span {
    margin-right: 10px;
  }
`;

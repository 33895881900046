import { CreateTimeOffDto } from '@bakeryerp/common';
import { Avatar, Badge, Card, List } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { api } from '../../../lib/api';
import { userSelector } from '../../../store/slices/userSlice';

export interface UserTimerOffsListProps {
  className?: string;
}

const getUserTimeOffs = async (query: any, userId: string) => {
  return api({
    method: 'GET',
    url: `/attendance/time-offs/${userId}`,
  }).then(({ data }) => {
    return data;
  });
};

const FlexCard = styled(Card)`
  width: 100%;
`;

const TimeOffHeading = styled.h3`
  display: inline-flex;
  margin-right: 8px;
`;

const statusColorMap = {
  approved: 'green',
  pending: 'gold',
  declined: 'red',
};

const UserTimerOffsListInner = (props: UserTimerOffsListProps) => {
  const user = useSelector(userSelector);
  const { data, isLoading } = useQuery(['getUserTimeOffs', user?.id], getUserTimeOffs);
  const { t } = useTranslation(['attendance', 'common']);
  return (
    <div className={props.className}>
      <List
        itemLayout="horizontal"
        dataSource={data}
        split={false}
        renderItem={(item: any) => (
          <List.Item>
            <FlexCard>
              <List.Item.Meta
                title={
                  <div>
                    <TimeOffHeading>{item.reason.value}: </TimeOffHeading>
                    {moment(item.start).format(
                      t('common:formats.dayAndMonth'),
                    )}{' '}
                    - {moment(item.end).format(t('common:formats.dayAndMonth'))}
                  </div>
                }
                description={
                  <Badge
                    color={statusColorMap[item.status]}
                    text={t(`timeOffs.status.${item.status}`)}
                  />
                }
              />
            </FlexCard>
          </List.Item>
        )}
      />
    </div>
  );
};

export const UserTimerOffsList = styled(UserTimerOffsListInner)``;

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { routes } from '../../../lib/routes';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../store/slices/userSlice';
import { AUTH_LOCAL_STORAGE_KEY } from '../../../lib/consts';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = useSelector(userSelector);
  return (
    <Route
      {...rest}
      render={(props) =>
        user != undefined ? (
          <Component {...props} />
        ) : (
          <Redirect to={routes.login.path} />
        )
      }
    />
  );
};

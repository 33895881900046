import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api } from '../../lib/api';
import {
	AUTH_LOCAL_STORAGE_KEY,
	JWT_LOCAL_STORAGE_KEY
} from '../../lib/consts';
import { clearEntries } from './entriesSlice';
import { clearTimer } from './timerSlice';

export const signUserOut = createAsyncThunk('user/signOut', async (payload, { dispatch }) => {
	dispatch(signOut);
	dispatch(clearEntries())
	dispatch(clearTimer())
	try {
		// await api({ method: 'post', url: '/auth/logout' });
	} catch (e) { }
});

export const syncUser = createAsyncThunk('user/sync', async (payload, { dispatch }) => {
	const { data } = await api({
		method: "get",
		url: `/user/current`
	})
	dispatch(signIn(data))
});

const authSlice = createSlice({
	name: 'auth',
	initialState: {
		user: null,
	},
	reducers: {
		signInFromLocalstorage: (state) => {
			try {
				state.user = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY));
			} catch { }
		},
		signIn: (state, action: PayloadAction<any>) => {
			state.user = action.payload;
			localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(state.user));
			localStorage.setItem(JWT_LOCAL_STORAGE_KEY, state.user.jwt);
		},
		signOut: (state) => {
			state.user = null;
			localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, null);
			localStorage.setItem(JWT_LOCAL_STORAGE_KEY, null);
		},
	},
	extraReducers: {
		[signUserOut.fulfilled.type]: (state, action) => {
			state.user = null;
		},
	},
});

export const userSelector = (state) => state.auth.user;
export const { signIn, signOut, signInFromLocalstorage } = authSlice.actions;
export const authReducer = authSlice.reducer;

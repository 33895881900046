import React from 'react';
import styled from 'styled-components';
import { TimerButton } from './components/TimerButton';
import { TimerDataContentRow } from './components/TimerDataContentRow';

export interface AttendanceTimerProps {
  className?: string;
}

const AttendanceTimerInner = (props: AttendanceTimerProps) => {
  return (
    <div className={props.className}>
      <TimerButton />
      <TimerDataContentRow />
    </div>
  );
};

export const AttendanceTimer = styled(AttendanceTimerInner)`
  width: 100%;
  background: white;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px;
  padding-top: 30px;
  position: relative;
  flex-flow: column;

  .attendance {
    &__icon {
      font-size: 48px;
    }
  }
`;

import React from 'react';
import { LoggedInLayout } from '../../components/shared/layout/LoggedInLayout';
import { AttendanceTimer } from '../../components/attendance/AttendanceTimer/AttendanceTimer';
import { Button, Card, Layout, PageHeader } from 'antd';
import { LayoutWithMargin } from '../../components/shared/LayoutWithMargin/LayoutWithMargin';
import { RestOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { CreateTimeOff } from '../../components/timeoffs/CreateTimeOff/CreateTimeOff';
import { UserTimerOffsList } from '../../components/timeoffs/UserTimerOffsList/UserTimerOffsList';

const TimeOffsPage = () => {
  const { t } = useTranslation(['attendance']);
  return (
    <LoggedInLayout>
      <>
        <PageHeader
          title={
            <>
              <RestOutlined /> {t('attendance:timeOffs.title')}{' '}
            </>
          }
          extra={<CreateTimeOff />}
          ghost
        />
        <LayoutWithMargin>
          <UserTimerOffsList />
        </LayoutWithMargin>
      </>
    </LoggedInLayout>
  );
};

export default TimeOffsPage;

import { PauseOutlined, CaretRightFilled } from '@ant-design/icons';
import React, { ReactElement, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useFormattedTimer } from '../../components/TimerRunningForDisplay/hooks/useFormattedTimer';
import cls from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  startedSelector,
  syncTimer,
  stopTimer,
  startTimer,
} from '../../../../store/slices/timerSlice';

export interface TimerButtonProps {
  className?: string;
  onClick: () => void;
}

const TimerButtonInner = (props: TimerButtonProps) => {
  const { className = '' } = props;
  const dispatch = useDispatch();
  const started = useSelector(startedSelector);

  const handleIconClick = useCallback(
    () => (started ? dispatch(stopTimer()) : dispatch(startTimer())),
    [started],
  );

  return (
    <div
      className={cls({
        [className]: true,
        running: started,
      })}
      onClick={handleIconClick}
    >
      {started ? (
        <PauseOutlined
          style={{
            fontSize: 38,
          }}
        />
      ) : (
        <CaretRightFilled
          style={{
            marginRight: -6,
          }}
        />
      )}
    </div>
  );
};

export const TimerButton = styled(TimerButtonInner)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;

  transition: all 230ms ease-in-out;

  border-radius: 50%;
  width: 78px;
  height: 78px;
  background: linear-gradient(138.46deg, #0066ff 0%, #33dbdb 143.93%);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  font-size: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
  color: white;

  &.running {
    background: linear-gradient(138.46deg, #f8343e 0%, #ff9c6e 143.93%);
  }
`;

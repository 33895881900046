import { ConfigProvider } from 'antd';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import sk_SK from 'antd/lib/locale-provider/sk_SK';
import moment from 'moment';
import 'moment/locale/sk';
import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './components/shared/routes/PrivateRoute';
import { routes } from './lib/routes';
import { syncEntries } from './store/slices/entriesSlice';
import { syncTimer } from './store/slices/timerSlice';
import { signInFromLocalstorage, syncUser } from './store/slices/userSlice';
import store from './store/store';

moment.locale('sk'); // for our general moment use

store.dispatch(signInFromLocalstorage());
store.dispatch(syncTimer());
store.dispatch(syncEntries());
store.dispatch(syncUser())

const App = () => {
  return (
    <ConfigProvider locale={sk_SK}>
      <BrowserRouter>
        <Switch>
          <Route path={routes.login.path} component={routes.login.component} />

          <PrivateRoute
            path={routes.dashboard.path}
            component={routes.dashboard.component}
          />

          <PrivateRoute
            path={routes.timer.path}
            component={routes.timer.component}
          />

          <PrivateRoute
            path={routes.timeOffs.path}
            component={routes.timeOffs.component}
          />

          <PrivateRoute
            path={routes.protected.path}
            component={routes.protected.component}
          />
          <Route path={'*'}>
            <Redirect to={routes.timer.path} />
          </Route>
        </Switch>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;

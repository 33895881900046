import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../store/slices/userSlice';

import { Layout, Menu, Drawer, Space } from 'antd';
import Icon, {
  CaretRightOutlined,
  ClockCircleOutlined,
  CloudDownloadOutlined,
  DashboardOutlined,
  MenuOutlined,
  RestOutlined,
  SettingOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { HeaderNameDropdown } from '../HeaderNameDropdown/HeaderNameDropdown';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routes } from '../../../lib/routes';

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

export interface AppHeaderProps {
  className?: string;
}

const DrawerLogo = styled.div`
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #001529;
  margin-bottom: 10px;
  color: white;
  font-weight: 600;
  font-size: 1.4rem;
`;

const AppHeaderInner = (props: AppHeaderProps) => {
  // const user = useSelector(userSelector);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const { t } = useTranslation(['attendance', 'settings']);

  return (
    <Layout.Header className={props.className}>
      <div className="header__open-sider">
        <MenuOutlined onClick={() => setDrawerOpen(!drawerOpen)} />
        <Drawer
          onClose={() => setDrawerOpen(false)}
          placement="left"
          width="250px"
          closable={false}
          visible={drawerOpen}
          bodyStyle={{ padding: 0 }}
        >
          <DrawerLogo>Maetrik Employee</DrawerLogo>
          <Menu
            defaultOpenKeys={['/' + location.pathname.split('/')[1]]}
            defaultSelectedKeys={[location.pathname]}
            mode="inline"
          >
            <SubMenu
              key="/attendance"
              icon={<ClockCircleOutlined />}
              title={t('attendance:title')}
            >
              <Menu.Item key={routes.timer.path} icon={<CaretRightOutlined />}>
                <Link to={routes.timer.path}>
                  {t('attendance:timer.title')}
                </Link>
              </Menu.Item>
              <Menu.Item key={routes.timeOffs.path} icon={<RestOutlined />}>
                <Link to={routes.timeOffs.path}>
                  {t('attendance:timeOffs.title')}
                </Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item icon={<SettingOutlined />}>
              <Link to={'/settings'}>{t('settings:title')}</Link>
            </Menu.Item>
          </Menu>
        </Drawer>
      </div>
      <HeaderNameDropdown />
    </Layout.Header>
  );
};

export const AppHeader = styled(AppHeaderInner)`
  padding: 0 20px;
  background: white;
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
`;

import { CreateDeviceLocationDto } from '@bakeryerp/common';
import { v4 } from 'uuid';

const getUA = () => {
	let device = "Unknown";
	const ua = {
		"Generic Linux": /Linux/i,
		"Android": /Android/i,
		"BlackBerry": /BlackBerry/i,
		"Bluebird": /EF500/i,
		"Chrome OS": /CrOS/i,
		"Datalogic": /DL-AXIS/i,
		"Honeywell": /CT50/i,
		"iPad": /iPad/i,
		"iPhone": /iPhone/i,
		"iPod": /iPod/i,
		"macOS": /Macintosh/i,
		"Windows": /IEMobile|Windows/i,
		"Zebra": /TC70|TC55/i,
	}
	Object.keys(ua).map(v => navigator.userAgent.match(ua[v]) && (device = v));
	return device;
}


export const getDeviceInfo = (): Promise<CreateDeviceLocationDto> => {
	return new Promise((resolve, reject) => {
		console.log('gettting location');
		if (navigator.geolocation) {
			try {
				navigator.geolocation.getCurrentPosition(({ coords }) => {
					const deviceLocation: CreateDeviceLocationDto = {
						id: v4(),
						lat: coords.latitude.toString(),
						lng: coords.longitude.toString(),
						...coords,
						device: getUA()
					}
					console.log('succes lcoation');

					resolve(deviceLocation);
				});
			} catch (e) {
				console.log('here', e);
				reject()
			}
		} else {
			console.log("Geo Location not supported by browser");
			reject(null)
		}
		reject(null)
	})
}
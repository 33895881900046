import React from 'react';
import { Login } from '../components/login/Login';

const LoginPage = () => (
  <>
    <Login />
  </>
);

export default LoginPage;
